import request from '@/utils/request'


// 查询帮助中心列表
export function listHelp(query) {
  return request({
    url: '/help/helps/list',
    method: 'get',
    params: query
  })
}

// 查询帮助中心分页
export function pageHelp(query) {
  return request({
    url: '/help/helps/page',
    method: 'get',
    params: query
  })
}

// 查询帮助中心详细
export function getHelp(data) {
  return request({
    url: '/help/helps/detail',
    method: 'get',
    params: data
  })
}

// 新增帮助中心
export function addHelp(data) {
  return request({
    url: '/help/helps/add',
    method: 'post',
    data: data
  })
}

// 修改帮助中心
export function updateHelp(data) {
  return request({
    url: '/help/helps/edit',
    method: 'post',
    data: data
  })
}

// 删除帮助中心
export function delHelp(data) {
  return request({
    url: '/help/helps/delete',
    method: 'post',
    data: data
  })
}
